<template>
  <div class="container">
    <div class="main">
      <div>
        <el-row>
          <!-- 侧边栏 -->
          <!-- <el-col :span="1" class="left-menu">
            <div class="wrapper-nav">
              <h4>账户中心</h4>
              <ul style="padding-left: 0">
                <li
                  :class="{ active: current == 1 }"
                  @click="goeEqurity"
                  v-if="role != 0"
                >
                  个人权益
                </li>
                <li :class="{ active: current == 2 }" @click="goMoneyBag">
                  我的钱包
                </li>
              </ul>
            </div>
          </el-col> -->
          <!-- 右侧内容 -->
          <el-col :span="24" style="margin-top: 10px; margin-left: 20px">
            <!-- 个人权益 -->
            <div v-if="current == 1">
              <div style="float: left; margin-right: 20px">
                <!-- 免费盒子 -->
                <div class="equity freeBox" v-if="role == 1">
                  <el-row>
                    <el-col :span="7">
                      <div class="freeImg">
                        <img src="../../assets/imgs/free.png" alt="" />
                      </div>
                    </el-col>
                    <el-col :span="17">
                      <div>
                        <div class="free">{{ edition }}</div>
                        <!-- <div class="now">
                        <span @click="goUp">立即升级</span>
                        <span v-if="ifRenew == true" @click="goRenew" style="color: #E94743; margin-left:50px;">立即续费</span>
                      </div> -->
                      </div>
                    </el-col>
                    <div class="freeIcon">免费</div>
                  </el-row>
                </div>
                <!-- vip盒子 -->
                <div class="equity" v-if="role == 2 || role == 3 || role == 4">
                  <el-row>
                    <el-col :span="7">
                      <div class="vipImg">
                        <img src="../../assets/imgs/Vip.png" alt="" />
                      </div>
                    </el-col>
                    <el-col :span="17">
                      <div>
                        <div class="tall">{{ edition }}</div>
                        <div class="end">到期时间: {{ endTime }}</div>
                        <!-- <div class="now" v-if="role != 3">
                        <span @click="goUp">立即升级</span>
                        <span v-if="ifRenew == true" @click="goRenew" style="color: #E94743; margin-left:50px;">立即续费</span>
                      </div> -->
                      </div>
                    </el-col>
                    <div class="vipIcon">VIP</div>
                  </el-row>
                </div>
              </div>

              <!-- vip权益列表 -->
              <div v-if="equityListShow">
                <div
                  class="equity equityList"
                  v-for="(item, index) in roleList"
                  :key="index"
                >
                  <el-row>
                    <el-col :span="7">
                      <div class="vipImg">
                        <img src="../../assets/imgs/Vip.png" alt="" />
                      </div>
                    </el-col>
                    <el-col :span="17">
                      <div>
                        <div class="tall">
                          {{
                            item.role == 2
                              ? "标准版"
                              : item.role == 3
                              ? "高级版"
                              : "定制版"
                          }}
                        </div>
                        <div class="end" style="margin-bottom: 10px">
                          开始时间: {{ item.startDate }}
                        </div>
                        <div class="end" style="margin-bottom: 10px">
                          到期时间: {{ item.expireDate }}
                        </div>
                        <!-- <div class="now" v-if="role != 3">
                        <span @click="goUp">立即升级</span>
                        <span v-if="ifRenew == true" @click="goRenew" style="color: #E94743; margin-left:50px;">立即续费</span>
                      </div> -->
                      </div>
                    </el-col>
                    <div class="vipIcon">VIP</div>
                  </el-row>
                </div>
              </div>

              <!-- 清除浮动 -->
              <div style="clear: both"></div>

              <!-- 个人权益 -->
              <div class="userEquity">
                <div class="alone">个人权益</div>
                <div>
                  <!-- 体验版 -->
                  <el-row v-if="role == 1">
                    <el-col :span="12">
                      <ul>
                        <li>支持采集总数量限制{{ maxTextNum }}条</li>
                        <li>支持免费提供爬虫模板{{ freeNum }}个</li>
                        <li>支持数据存储时间六个月</li>
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>支持采集任务并发数量1个</li>
                        <li>支持采集结果导出</li>
                      </ul>
                    </el-col>
                  </el-row>
                  <!-- 高级版 -->
                  <el-row v-if="role == 2">
                    <el-col :span="12">
                      <ul>
                        <li>支持采集总数量限制{{ maxTextNum }}条</li>
                        <li>支持免费提供爬虫模板{{ freeNum }}个</li>
                        <li>支持数据存储时间一年</li>
                        <li>支持多采集任务并发</li>
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>支持采集结果导出</li>
                        <li>支持高级采集执行策略</li>
                        <li>支持采集任务较优</li>
                      </ul>
                    </el-col>
                  </el-row>
                  <!-- 专业版 -->
                  <el-row v-if="role == 3">
                    <el-col :span="12">
                      <ul>
                        <li>支持采集总数量限制{{ maxTextNum }}条</li>
                        <li>支持免费提供爬虫模板{{ freeNum }}个</li>
                        <li>支持数据存储时间三年</li>
                        <li>支持多采集任务并发</li>
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>支持采集结果导出</li>
                        <li>支持高级采集执行策略</li>
                        <li>支持采集任务优先</li>
                        <li>支持分布式集群采集</li>
                      </ul>
                    </el-col>
                  </el-row>
                  <!-- 定制版 -->
                  <el-row v-if="role == 4">
                    <el-col :span="12">
                      <ul>
                        <li>支持采集总数量无限量</li>
                        <li>支持免费提供无限量爬虫模板</li>
                        <li>支持数据永久存储</li>
                        <li>支持多采集任务并发</li>
                        <li>支持采集结果导出</li>
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>支持高级采集执行策略</li>
                        <li>支持采集任务最优</li>
                        <li>支持分布式集群采集</li>
                        <li>支持私有云独立部署</li>
                      </ul>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <!-- 我的钱包 -->
            <div v-if="current == 2">
              <div style="overflow: hidden">
                <!-- 账户余额盒子 -->
                <div class="accountBalance equity">
                  <el-row>
                    <el-col :span="10">
                      <div class="balance">
                        <img src="../../assets/imgs/money.png" alt="" />
                        <span>账户余额</span>
                      </div>
                    </el-col>
                    <el-col :span="14">
                      <div class="money">
                        <span class="icon">￥</span>
                        <span class="number">
                          {{ accountBalance }}
                          <span style="font-size: 13px; padding-left: 3px">
                            元
                          </span>
                        </span>
                        <div class="now" @click="recharge">立即充值</div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <!-- 可提现盒子 -->
                <div class="withdraw equity">
                  <el-row>
                    <el-col :span="12">
                      <div class="balance">
                        <img src="../../assets/imgs/tiXian.png" alt="" />
                        <span>可提现余额</span>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="money">
                        <span class="icon">￥</span>
                        <span class="number">
                          {{ withdraw }}
                          <span style="font-size: 13px; padding-left: 3px">
                            元
                          </span>
                        </span>
                        <div class="now" @click="getWithDraw">前往提现</div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>

              <!-- 消费记录表 -->
              <div class="consume">
                <div class="alone">消费记录表</div>
                <div class="consumeList" v-show="consumeData.length > 0">
                  <el-table :data="consumeData" stripe style="width: 100%">
                    <el-table-column
                      prop="outTradeNo"
                      label="订单编号"
                      align="center"
                    ></el-table-column>
                    <el-table-column label="支付类型" width="90" align="center">
                      <template v-slot="scope">
                        <div>
                          <span>
                            {{ scope.row.payType == 2 ? "收入" : "支出" }}
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="消费金额" width="90" align="center">
                      <template v-slot="scope">
                        <div>
                          <span v-if="scope.row.payType == 2">+</span>
                          <span v-if="scope.row.payType != 2">-</span>
                          <span>{{ scope.row.amount }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="createTime"
                      label="消费时间"
                      width="180"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="message"
                      label="消费信息"
                      align="center"
                    ></el-table-column>
                  </el-table>
                </div>
                <!-- 分页 -->
                <div
                  style="margin-bottom: 10px"
                  v-show="consumeData.length > 0"
                >
                  <el-pagination
                    @size-change="sizeChange"
                    @current-change="changePage"
                    @prev-click="prevClick"
                    @next-click="nextClick"
                    :current-page="currentPage"
                    background
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, sizes, jumper"
                    :total="totalCount"
                  ></el-pagination>
                </div>
                <div v-show="consumeData.length <= 0" class="noValue">
                  暂无数据
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { httpPost } from "@/api/httpService.js";

export default {
  data() {
    return {
      current: 1,
      edition: "", // 版本
      role: 1, // 角色
      endTime: "",
      maxTextNum: 0, // 支持采集总数量限制
      freeNum: 0, // 支持免费提供爬虫模板
      accountBalance: 0, // 账户余额
      withdraw: 0, // 可提现余额
      ifRenew: false, // 是否续费
      consumeData: [], // 消费记录表格
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      userId: null,
      roleList: [], //用户暂未开始的权益列表
      equityListShow: false, //是否显示权益列表
    };
  },

  created() {
    let userInfo = VueCookies.get("userInfo");
    this.userId = userInfo.userId;
    this.getRole();
    this.setBreadcrumb();
  },

  emits: ["setBreadcrumbList", "setPageTitle"], // 声明将要触发的自定义事件

  methods: {
    // 获取角色个人权益
    getRole() {
      httpPost("/user/v1/getUserPermission").then((res) => {
        if (res.code == 0) {
          this.role = res.data.role;
          this.roleList = JSON.parse(res.data.roleList);
          // this.endTime = res.data.expireTime;
          if (this.roleList && this.roleList.length) {
            this.equityListShow = true;
          } else {
            this.equityListShow = false;
          }
          // if (this.role == 0) {
          //   this.current = 2
          // }
          // this.maxTextNum = res.data.maxTextNum
          // this.freeNum = res.data.freeNum
          this.endTime = this.getDate(res.data.expireTime);
          if (this.role == 1) {
            this.edition = "体验版";
          } else if (this.role == 2) {
            this.edition = "标准版";
          } else if (this.role == 3) {
            this.edition = "高级版";
          } else if (this.role == 4) {
            this.edition = "定制版";
          }
          // 当结束时间与当前时间相差七天时显示立即续费
          var now = new Date();
          var year = now.getFullYear(); //得到年份
          var month = now.getMonth(); //得到月份
          var date = now.getDate(); //得到日期
          var day = now.getDay(); //得到周几
          var hour = now.getHours(); //得到小时
          var minu = now.getMinutes(); //得到分钟
          var sec = now.getSeconds(); //得到秒
          month = month + 1;
          if (month < 10) month = "0" + month;
          if (date < 10) date = "0" + date;
          if (hour < 10) hour = "0" + hour;
          if (minu < 10) minu = "0" + minu;
          if (sec < 10) sec = "0" + sec;
          var time = "";
          time =
            year +
            "-" +
            month +
            "-" +
            date +
            "" +
            " " +
            hour +
            ":" +
            minu +
            ":" +
            sec +
            " ";
          var d1 = new Date(this.endTime);
          var d2 = new Date(time);
          let dValue = (d1 - d2) / 3600000 / 24;
          if (dValue <= 7 && dValue > 0) {
            this.ifRenew = true;
          } else {
            this.ifRenew = false;
          }
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },

    // 获取消费记录
    getConsume() {
      // TODO 重新对接-废弃？
      httpPost("/center/v1/record", {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        userId: this.userId,
      }).then((res) => {
        if (res.code == 0) {
          this.consumeData = res.data.data;
          this.totalCount = res.data.total;
          this.consumeData.forEach((item) => {
            if (item.type == 1) {
              item.type = "体验版";
            } else if (item.type == 2) {
              item.type = "高级版";
            } else if (item.type == 3) {
              item.type = "专业版";
            } else if (item.type == 4) {
              item.type = "定制版";
            }
            item.createTime = this.getDate(item.createTime);
          });
        }
      });
    },

    getDate(date) {
      var dateTemp = new Date(date).toJSON();
      var dateRes = new Date(+new Date(dateTemp) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      return dateRes;
    },

    // 前往个人权益
    goeEqurity() {
      this.current = 1;
    },

    // 前往我的钱包
    goMoneyBag() {
      this.current = 2;
      // TODO 重新对接-废弃？
      httpPost("/order/amount").then((res) => {
        if (res.code == 0) {
          this.accountBalance = res.data.balance;
          this.withdraw = res.data.useBalance;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
      this.getConsume();
    },

    // 立即充值
    recharge() {
      window.open("https://i.ringdata.com/balance");
    },

    // 立即提现
    getWithDraw() {
      window.open("https://i.ringdata.com/index");
    },

    // 立即续费
    goRenew() {
      this.$router.push("/pricing");
    },

    // 立即升级
    goUp() {
      this.$router.push("/pricing");
    },

    // 每页记录数改变时
    sizeChange(size) {
      this.pageSize = size;
      this.getConsume();
    },

    // 当前页改变时
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.getConsume();
    },

    // 上一页
    prevClick(currentPage) {
      this.currentPage = currentPage;
      this.getConsume();
    },

    // 下一页
    nextClick(currentPage) {
      this.currentPage = currentPage;
      this.getConsume();
    },

    //设置面包屑
    setBreadcrumb() {
      const breadcrumbList = [
        {
          title: "个人权益",
          name: "UserEquity",
          params: {},
        },
      ];
      const pageTitle = "个人权益";
      this.$emit("setBreadcrumbList", breadcrumbList);
      this.$emit("setPageTitle", pageTitle);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .menus {
    margin-top: 20px;
    margin-bottom: 60px;
    li {
      float: left;
      list-style: none;
    }
  }
}
.noValue {
  width: 100%;
  text-align: center;
  padding: 30px 240px 30px 0;
}
.left-menu {
  .avatar {
    text-align: center;
    img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
    }
    p {
      text-align: center;
      font-size: 16px;
    }
  }
  .wrapper-nav {
    text-align: center;
    h4 {
      font-size: 15px;
    }

    li {
      cursor: pointer;
      font-size: 14px;
      color: #111;
      display: block;
      text-decoration: none;
      padding: 5px 0px;
      &.active {
        color: #760478;
      }
    }
  }
}
.freeBox {
  background-color: #eff7e3 !important;
}
.equity {
  margin-top: 10px;
  width: 290px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff4da;
  padding: 8px;
  position: relative;
  overflow: hidden;
  .vipImg {
    width: 55px;
    height: 55px;
    margin-bottom: 35px;
    padding-top: 10px;
    text-align: center;
    background-color: #ff847e;
    border-radius: 50%;
    img {
      width: 35px;
    }
  }
  .freeImg {
    width: 55px;
    height: 55px;
    margin-bottom: 35px;
    img {
      width: 55px;
    }
  }
  .free {
    font-size: 16px;
    color: #49bd8f;
    height: 16px;
    margin-bottom: 8px;
  }
  .tall {
    font-size: 16px;
    color: #e94743;
    height: 16px;
    margin-bottom: 8px;
  }
  .end {
    font-size: 11px;
    color: #999;
    margin-bottom: 20px;
  }
  //
  .now {
    font-size: 13px;
    color: #3385c2;
    cursor: pointer;
  }
  .vipIcon {
    width: 80px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff4da;
    background-color: #f1bd50;
    position: absolute;
    top: 0px;
    right: -32px;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg); /* IE 9 */
    -moz-transform: rotate(45deg); /* Firefox */
    -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
    -o-transform: rotaterotate(45deg); /* Opera */
  }
  .freeIcon {
    width: 80px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff4da;
    background-color: #49bd8f;
    position: absolute;
    top: 0px;
    right: -32px;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg); /* IE 9 */
    -moz-transform: rotate(45deg); /* Firefox */
    -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
    -o-transform: rotaterotate(45deg); /* Opera */
  }
}
.equityList {
  background-color: #fff;
  float: left;
  margin-right: 18px;
}
.userEquity {
  margin-top: 50px;
  .alone {
    color: #760478;
    margin-bottom: 30px;
  }
  li {
    list-style: outside;
    color: #777;
    margin-bottom: 10px;
    margin-left: 18px;
  }
}
.accountBalance {
  float: left;
  padding: 10px;
  background-color: #f6f6f6 !important;
  border: 1px solid #e1e1e1 !important;
  .balance {
    width: 100%;
    span {
      color: #666;
    }
    img {
      width: 40px;
      vertical-align: middle;
    }
  }
  .money {
    text-align: right;
    .icon {
      color: #e94743;
      font-size: 14px;
    }
    .number {
      color: #e94743;
      font-size: 28px;
    }
    .now {
      margin-top: 25px;
    }
  }
}
.withdraw {
  float: left;
  margin-left: 100px;
  padding: 10px;
  background-color: #f6f6f6 !important;
  border: 1px solid #e1e1e1 !important;
  .balance {
    width: 100%;
    span {
      color: #666;
    }
    img {
      width: 40px;
      vertical-align: middle;
    }
  }
  .money {
    text-align: right;
    .icon {
      color: #e94743;
      font-size: 14px;
    }
    .number {
      color: #e94743;
      font-size: 28px;
    }
    .now {
      margin-top: 25px;
    }
  }
}
.consume {
  margin-top: 50px;
  .alone {
    color: #760478;
    margin-bottom: 30px;
  }
  .consumeList {
    margin-bottom: 30px;
  }
}
</style>
